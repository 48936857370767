import React from "react"
import { Parallax } from 'react-scroll-parallax';

const LogoHorizontal= () => (
  <Parallax className="c-logo-horizontal-parallax" y={[5, -20]} tagOuter="div">
        <svg
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit="2"
        clipRule="evenodd"
        viewBox="0 0 2038 346"
        className="c-logo-horizontal"
        >
        <g fill="#fff" fillRule="nonzero" transform="scale(4.16667)">
            <path d="M53.829 27.165l5.148 16.093h.129l1.341-5.147 3.85-11.421h7.701v1.168L61.486 57.274H57.03l-4.845-14.578h-.13L47.21 57.274h-4.282L32.286 27.858V26.69h7.83l3.893 11.421 1.341 5.147h.13l5.061-16.093h3.288zM96.697 38.586l-3.418 7.959h6.922l-3.504-7.959zm6.013 14.579H90.771l-1.774 3.806h-7.57v-1.125l13.626-29.545h3.331l13.67 29.545v1.125h-7.57l-1.774-3.806zM142.248 33.612c-1.817-.044-3.721 0-5.537 0v7.57h5.537c4.196 0 4.412-7.527 0-7.57zm12.329 22.234v1.125h-8.652l-5.191-8.911h-4.023v8.911h-7.745V26.69h13.282c11.896.043 14.059 14.362 6.402 19.553l5.927 9.603zM191.128 33.612h-11.203v4.282h10.295v6.878h-10.295v5.321h11.593v6.878h-19.293V26.69h18.903v6.922zM234.646 56.971h-7.917V44.816h-9.343v12.155h-7.917V26.69h7.917v10.988h9.343V26.69h7.917v30.281zM274.054 41.873c0-10.9-15.184-10.9-15.184 0 0 10.989 15.184 10.989 15.184 0zm-23.144 0c0-20.936 31.06-20.936 31.06 0 0 20.981-31.06 20.981-31.06 0zM315.365 46.762V26.69h7.397v20.202c0 6.662-5.754 10.512-11.983 10.512-6.186 0-12.026-3.85-12.026-10.512V26.69h7.397v20.072c0 2.466 2.38 3.677 4.629 3.677 2.25 0 4.586-1.211 4.586-3.677zM355.508 48.06c0-2.12-2.509-2.596-5.537-2.769-6.576-.475-10.902-2.725-10.988-9.3-.26-13.065 23.273-13.065 23.1 0h-7.354c0-4.024-8.133-3.677-8.392-.087-.173 2.293 2.076 2.768 4.802 3.071 6.012.649 11.896 1.558 11.896 9.128 0 12.718-24.658 12.935-24.485-.39h7.484c0 3.851 9.474 3.894 9.474.347zM398.852 33.612h-11.204v4.282h10.295v6.878h-10.295v5.321h11.593v6.878h-19.293V26.69h18.904v6.922zM463.621 28.635c1.327-.791 2.004-1.806 2.004-3.416 0-1.693-1.157-3.556-3.02-3.556-1.666 0-2.541 1.637-3.049 2.963l-.593 1.525c-1.185 3.02-2.851 5.279-6.407 5.279-3.868 0-6.634-2.992-6.634-6.804 0-3.443 2.54-6.126 5.956-6.577l.565 2.682c-2.343-.028-4.037 1.835-4.037 4.121 0 2.287 1.92 3.924 4.15 3.924 2.286 0 3.274-1.835 4.036-3.669l.621-1.469c1.045-2.427 2.428-4.629 5.392-4.629 3.444 0 5.505 3.049 5.505 6.267 0 2.371-1.158 4.319-3.218 5.476l-1.271-2.117z"></path>
            <path d="M446.374 39.334H467.658V41.988H446.374z"></path>
            <path d="M467.658 49.834v3.021l-7.763 4.348 7.763 4.629v3.02l-10.134-6.125-11.15 6.464v-2.964l8.722-5.024-8.722-5.223v-2.964l11.15 6.691 10.134-5.873z"></path>
            <path d="M483.667 78.5h-53.474V5h53.474v73.5zM4.5 5h421.193v73.5H4.5V5zM0 .5V83h488.167V.5H0z"></path>
        </g>
        </svg>
    </Parallax>
)

export default LogoHorizontal
