import React from "react"
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import { Parallax } from 'react-scroll-parallax';
import { Reveal, Tween } from 'react-gsap';
import { Helmet } from "react-helmet"
import Layout from "../layouts/default"
import SEO from "../components/seo"
import Arrow from "../components/arrow"
import LogoHorizontal from "../components/logo-horizontal"
import bbc from "../images/clients/bbc.png" 
import bupa from "../images/clients/bupa.png" 
import c4 from "../images/clients/c4.png" 
import clubnme from "../images/clients/clubnme.png" 
import frankies from "../images/clients/frankies.png" 
import ge from "../images/clients/ge.png" 
import holidayinn from "../images/clients/holiday-inn.png" 
import homebase from "../images/clients/homebase.png" 
import nhs from "../images/clients/nhs.png" 
import roundhouse from "../images/clients/roundhouse.png" 
import santander from "../images/clients/santander.png" 
import superamart from "../images/clients/superamart.png" 

const Approach = () => {

  const images = useStaticQuery(graphql`
    query {
      Approach1: file(relativePath: { eq: "approach-1@2x.jpg" }) {
        childImageSharp {
          fixed(width: 813)  {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      Approach2: file(relativePath: { eq: "approach-2@2x.jpg" }) {
        childImageSharp {
          fixed(width: 536)  {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      Approach3: file(relativePath: { eq: "approach-3@2x.jpg" }) {
        childImageSharp {
          fixed(width: 536)  {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
    `)

  return (

    <Layout>

      <SEO title="Branding, Print and Digital Agency based in Manchester &amp; Melbourne." />
      <Helmet bodyAttributes={{ class: "s-approach" }} />

      <LogoHorizontal /> 

      <section className="o-section u-font-large">
        <div className="u-wrapper">
          <div className="u-wrapper__inner u-wrapper__inner--right">      
            <p>We transform brands, grow businesses and tell stories that deepen customer engagements. Based in Manchester &amp; Melbourne, our roots lie in the craft of branding, print and digital across all platforms.</p>
            <p>Experience tells us that the best results are always achieved through close collaboration with our clients. Whilst we work with global corporates, we are equally passionate about independent local projects. Most importantly for us, Warehouse Six will remain a small, approachable, independent creative consultancy able to service each of its clients thoroughly and with integrity.</p>
            <Arrow class="c-arrow c-arrow--reverse c-arrow--down" />
          </div>
        </div>
    
        <Parallax className="o-parallax-image o-parallax-image--right o-parallax-image--top" y={[30, -40]} tagOuter="figure">
          <Img fixed={images.Approach1.childImageSharp.fixed} alt="Desk" />
        </Parallax>      
       
      </section>
     
      <section className="o-section o-section--no-pad-top o-section--no-pad-bottom u-font-large">
        <div className="u-wrapper u-wrapper--left u-columns u-columns--2 u-columns--dense u-columns--justify-left">
          <div className="u-columns__col-2">  
            <Reveal>
              <Tween
                from={{ y: '30px', opacity: '0' }}
                to={{ y: '0px', opacity: '1' }}
                duration={.5}
              >
                <div>
                  <p>The main focus of our studio is the design and creation of bespoke content managed websites, digital solutions as well as considered, crafted brand identities.</p>
                  <p>Since our inception we have worked across such a diverse range of industries including exhibitions, advertising, packaging, festivals, finance, retail, hospitality, digital publishing and many more.</p>
                </div>
              </Tween> 
          </Reveal>
          </div>
          <Parallax className="u-columns__col-1 o-parallax-image o-parallax-image--left o-parallax-image--top-bottom" y={[20, -60]} tagOuter="figure">
            <Img fixed={images.Approach2.childImageSharp.fixed} alt="Office Interior" />
          </Parallax>
        </div>
      </section>

      <section className="o-section o-section--no-pad-bottom u-font-large">
        <div className="u-wrapper">
          <div className="c-services u-columns u-columns--2 u-columns--no-gap"> 
            <Reveal>
              <Tween
                from={{ y: '30px', opacity: '0' }}
                to={{ y: '0px', opacity: '1' }}
                duration={.5}
              >
              <div>         
                <p>We include a diverse, high-level skill set drawn from disciplines including branding, graphic design, exhibition design, user experience development, database-driven website programming and bespoke software development. </p>
                <ul>
                  <li>Digital Design</li>
                  <li>Development</li>
                  <li>E-commerce</li>
                  <li>Content managed websites</li>
                  <li>Email Marketing </li>
                  <li>Brand Identity</li>
                  <li>Design</li>
                  <li>Art Direction</li>
                  <li>Advertising</li>
                  <li>Packaging</li>
                </ul>
              </div>
              </Tween> 
            </Reveal>
            <Parallax className="o-parallax-image o-parallax-image--right" y={[0, -60]} tagOuter="figure">
              <Img fixed={images.Approach3.childImageSharp.fixed} alt="Office Exterior" />
            </Parallax>
          </div>
        </div>
      </section>

      <section className="o-section c-clients u-bg-white">
        <div className="u-wrapper u-wrapper--relative">
          <h2 className="u-heading">Some of our past clients</h2>
          <Reveal>
            <Tween
              from={{ y: '30px', opacity: '0' }}
              to={{ y: '0px', opacity: '1' }}
              duration={.5}
            >
              <ul className="c-clients__logos">
                <li><img src={bbc} alt="BBC"/></li>
                <li><img src={bupa} alt="bupa"/></li>
                <li><img src={c4} alt="c4"/></li>
                <li><img src={clubnme} alt="clubnme"/></li>
                <li><img src={frankies} alt="frankies"/></li>
                <li><img src={holidayinn} alt="holidayinn"/></li>
                <li><img src={homebase} alt="homebase"/></li>
                <li><img src={ge} alt="ge"/></li>
                <li><img src={nhs} alt="nhs"/></li>
                <li><img src={roundhouse} alt="roundhouse"/></li>
                <li><img src={santander} alt="santander"/></li>
                <li><img src={superamart} alt="superamart"/></li>
              </ul>
              </Tween> 
            </Reveal>
        </div>
      </section>

      <section className="o-section c-contacts">
        <div className="u-wrapper">
            <div className="u-wrapper__inner u-wrapper__inner--right"> 
              <h2 className="u-heading u-heading--pull-left u-heading--reverse u-heading--with-arrow">Get in touch <Arrow class="c-arrow c-arrow--reverse" /></h2>
              <div className="c-contact">
                <div className="c-contact__location">
                  <h3>Manchester</h3>
                  <a href="mailto:alex@warehousesix.com">alex@warehousesix.com</a>
                </div>
                <div className="c-contact__location">
                  <h3>Melbourne</h3>
                  <a href="mailto:darren@warehousesix.com">darren@warehousesix.com</a>
                </div>
              </div>
            </div>
          </div>
        </section>
  
    </Layout>
  
    )

    

}

export default Approach


